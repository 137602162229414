/** On peut décomposer les routes par module**/
import Layout from '@/layout';

const ProduitRoutes = {
  path: '/produits',
  component: Layout,
  redirect: '/produits/index',
  name: 'Produit',
  meta: {
    title: 'produits',
    icon: 'el-icon-tickets',
    permissions: ['manage produit'],
  },
  children: [
    {
      path: 'create',
      component: () => import(/* webpackChunkName: "create_produit" */'@/views/produits/Create'),
      name: 'CreateProduit',
      meta: { title: 'createProduit', icon: 'edit', permissions: ['create produit'] },
      hidden: true,
    },
    {
      path: 'edit/:id(\\d+)',
      component: () => import(/* webpackChunkName: "edit_produit" */'@/views/produits/Edit'),
      name: 'EditProduit',
      meta: { title: 'editProduit', noCache: true, permissions: ['edit produit'] },
      hidden: true,
    },
    {
      path: 'index',
      component: () => import(/* webpackChunkName: "list_produits" */ '@/views/produits/List'),
      name: 'ProduitList',
      meta: { title: 'produits', icon: 'el-icon-tickets', permissions: ['list produit'] },
    },
    {
      path: 'categories',
      component: () => import(/* webpackChunkName: "categories_tags" */ '@/views/produits/Categories_tags'),
      name: 'Categories',
      meta: { title: 'categoriestags', icon: 'el-icon-edit', permissions: ['manage produit'] },
    },
  ],
};

export default ProduitRoutes;

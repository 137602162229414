export default {
  general: {
    close: 'Fermer',
    description: 'Description',
    code: 'Code',
    nom: 'Nom',
    libelle: 'Libelle',
    title: 'Titre',
    type: 'Type',
    search: 'Chercher',
    add: 'Ajouter',
    export: 'Exporter',
    id: 'ID',
    date: 'Date',
    author: 'Auteur',
    status: 'Statut',
    actions: 'Actions',
    edit: 'Modifier',
    delete: 'Supprimer',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    keyword: 'Mots clés',
    role: 'Role',
    permissions: 'Permissions',
    menus: 'Menus',
    rolesEtpermissions: 'Rôles et permissions',
    deleteWarningText: 'Êtes-vous sûr de vouloir supprimer ?',
    deleteConfirmButtonText: 'Oui supprimer',
    deleteCancelButtonText: 'Non laisser',
    save: 'Sauvegarder',
    saving: 'Sauvegarde ...',
    closingWarningText: 'Êtes-vous sûr de vouloir fermer ?',
    notFound: 'Aucun élément trouvé',
    actives: 'Actives',
    visible: 'Visible',
    disponibles: 'Disponibles',
    M_actives: 'Activés',
    F_actives: 'Activées',
    M_desactives: 'Désactivés',
    F_desactives: 'Désactivées',
    M_supprimes: 'Supprimés',
    F_supprimes: 'Supprimées',
    corbeille: 'Corbeille',
    restaurer: 'Restaurer',
    suppressionDefinitive: 'Suppression définitive',
    nonActives: 'Non Actives',
    nonVisibles: 'Non Visibles',
    F_actif: 'Active',
    M_actif: 'Actif',
    deleteContinue: 'Continuer ?',
    suppressionAnnule: 'Suppression annulée',
    filter: 'Filtrer',
    init: 'Réinitialiser',
    statusUpdated: 'Mise à jour du status éffectuée avec succès !',
    statusUpdateError: "Désolé, une erreur s'est produite lors de la mise à jour du status",
    ouiSupprimer: 'Oui supprimer',
    irrversibleMessage: 'Cette action est irréversible',
    SureQuestion: 'Etes-vous sûre ?',
    periode: 'Période',
    startDateplaceholder: 'Date de début',
    endDateplaceholder: 'Date de fin',
    DaterangeSeparator: 'à',
    ImapeUploadFieldText: 'Cliquer pour envoyer',
    clotures: 'Clôturés',
    labelColonneCloturer: 'clôturée',
    allpermissions: 'Toutes les permissions',
    formInvalid: 'Votre formulaire n\'est pas valide ! Veuillez vérifier les données !',
    admins: 'Admins',
    donors: 'Donors',
    recipients: 'Recipients',
    others: 'Autres',
    processing: 'Traitement',
    success: 'Success',
    error: 'Error',
    disabled: 'Disabled',
    enabled: 'Enabled',
    details: 'Détails',
    yes: 'Yes',
    no: 'No',
  },
  route: {
    dashboard: 'Accueil',
    introduction: 'Introduction',
    documentation: 'Documentation',
    guide: 'Guide',
    permission: 'Permission',
    pagePermission: 'Page de Permission',
    rolePermission: 'Role et Permission',
    icons: 'Icones',
    components: 'Composants',
    componentIndex: 'Introduction',
    errorPages: "Pages d'erreur",
    page401: '401',
    page404: '404',
    errorLog: 'Log',
    administration: 'Administration',
    users: 'Utilisateurs',
    userProfile: 'Profile Utilisateur',
    regions: 'Les régions',
    parcelles: 'Les parcelles',
    campagneagricoles: 'Campagnes agricoles',
    alerts: 'Les Alerts',
    localites: 'Les localités',
    reports: 'Reports',
    commandes: 'Commandes',
    produits: 'Produits',
    tags: 'Tags',
    categoriestags: 'Catégories et tags',
    editProduit: 'Modifier le produit',
    discussions: 'Discussions',
    UserProfile: 'Profile',
  },
  navbar: {
    logOut: 'Déconnexion',
    dashboard: 'Accueil',
    github: 'Github',
    theme: 'Theme',
    size: 'Taille globale',
    profile: 'Profile',
  },
  login: {
    title: 'Se connecter',
    logIn: 'Connecté',
    username: 'Identifiant',
    password: 'Mot de passe',
    email: 'Adresse email',
  },
  permission: {
    addRole: 'Nouveau Role',
    editPermission: 'Modifier la Permission',
    editPermissions: 'Modifier les Permissions',
    roles: 'Roles',
    switchRoles: 'Activer les roles',
    delete: 'Supprimer',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    permissionsUpdated: 'Les permissions ont bien été mises à jour !',
    extraPermissions: 'Permissions supplémentaires',
    addedPermissions: 'Permissions ajoutées',
    noSelectionAdd: 'Aucune permission ajoutée',
    manage: 'Gérer les permissions',
    addPermissions: 'Ajouter des permissions',
    tableName: 'Nom de la table',
    NameRequired: 'Veuillez saisir le nom',
    iscreatedSuccessfully: 'Permission ajoutée avec succès',
    name: 'Permission',
    suppressionEffectue: 'Permission supprimée avec succès',
  },
  excel: {
    export: 'Exporter',
    selectedExport: 'Exporter les éléments sélectionnés',
    placeholder: "Entrez le nom du fichier s'il vous plaît (par défaut : liste-excel)",
  },
  theme: {
    change: 'Changer le Theme',
    documentation: 'documentation du Theme',
    tips: "Conseils: Il est différent du choix de thème sur la barre de navigation, ce sont deux méthodes de skinning différentes, chacune avec des scénarios d'application différents.",
  },
  settings: {
    title: 'Configuration du style de Page',
    theme: 'Couleur du Theme',
    tagsView: 'Afficher les tags',
    fixedHeader: "Fixer la d'entête",
    sidebarLogo: 'Logo',
  },
  tagsView: {
    refresh: 'Rafraîchir',
    close: 'Fermer',
    closeOthers: 'Fermer les autres',
    closeAll: 'Fermer tout',
  },
  user: {
    name: 'Name',
    user: 'Utilisateur',
    role: 'Role',
    password: 'Mot de passe',
    c_password: 'Confirmer',
    nom: 'Nom',
    prenom: 'Prenom',
    email: 'Adresse Email',
    username: 'Identifiant',
    code: 'Code',
    EditPermissions: 'Modifier les permissions',
    AddUser: 'Ajouter un utilisateur',
    selectRole: 'Sélectionnez un rôle',
    PasswordsInconform: 'Les mots de passes ne sont pas conformes',
    RoleRequired: 'Veuillez sélectionner un rôle',
    NomRequired: 'Veuillez saisir le nom',
    EmailRequired: 'Veuillez saisir le mail',
    EmailInvalide: 'Veuillez saisir une adresse email valide',
    PasswordRequired: 'Veuillez entrer le mot de passe',
    deleteWarn: "Ceci supprimera l'utilisateur",
    deleteContinue: 'Continuer ?',
    cancel: 'Annuler',
    suppressionEffectue: 'Suppression effectuée avec succès!',
    suppressionAnnule: 'Suppression annulée',
    NewUser: 'Nouveau utilisateur',
    iscreatedSuccessfully: 'a été crée avec succès',
    PermissionsUpdated: 'Permissions mise à jour avec succès!',
    users: 'Utilisateurs',
    edit: "Modifier l'utilisateur",
    PasswordMin6: 'Doit avoir au moins 6 caractères',
    isupdatedSuccessfully: "Mise à jour de l'utilisateur éffectuée avec succès !",
    changeCredentils: 'Modifier le mot de passe',
    nochangeCredentils: 'Ne pas modifier le mot de passe',
    changeAvatar: 'Changer l\'avatar',
    account: 'Compte',
    enterPassword: 'Entrez votre mots de passe pour continuer',
    profileUpdated: 'Votre profile a bien été mise à jour !',
  },
  roles: {
    deleteWarn: 'Attention, ceci supprimera le role',
    permissions: 'Permissions',
    nom: 'Nom du rôle',
    users: 'Utilisateurs',
    extraMenus: 'Menus supplémentaires',
    InheritedfromRole: 'Hérité du rôle',
    addRole: 'Ajouter un rôle',
    name: 'Role',
    NewRole: 'Nouveau rôle',
    iscreatedSuccessfully: 'crée avec succès!',
    isupdatedSuccessfully: 'Le rôle a été mis à jour avec succès',
    NameRequired: 'Veillez saisir le rôle',
    edit: 'Modifier le rôle',
    suppressionEffectue: 'Rôle supprimé avec succès',
    description: {
      admin: "Le Super Administrateur administre l'application",
      manager: "Le Manager est le gérant de l'application",
      editeur: 'Utilisateur pouvant intégrer des données',
      utilisateur: 'Utilisateur normal',
      visiteur: 'les visiteurs ont juste un droit de visualisation',
    },
  },
  alert: {
    alert: 'Alert',
    alerts: 'Les alerts',
    subject: 'Le sujet',
    body: 'Description',
    SubjectRequired: 'Veuillez saisir le subject de la alert',
    BodyRequired: 'Dites-nous en plus !',
    deleteWarn: 'Ceci supprimera la alert',
    suppressionEffectue: 'Alert supprimé avec succès',
    NewAlert: 'Nouvelle Alert',
    iscreatedSuccessfully: 'crée avec succès',
    isupdatedSuccessfully: "Mise à jour de l'alert éffectuée avec succès !",
    created_at: 'Crée le',
    edit: "Modifier l'alert",
    AddAlert: 'Nouvelle alert',
    UserRequired: 'Vous devez sélectionner un utilisateur',
    selectAlertTitle: "Sélectionnez l'alert",
  },
  commande: {
    commande: 'Commande',
    commandes: 'Les Commandes',
    type: 'Type',
    deleteWarn: 'Ceci supprimera la commande',
    suppressionEffectue: 'commande supprimée avec succès',
    NewCommande: 'Nouvelle Commande',
    iscreatedSuccessfully: 'créee avec succès',
    isupdatedSuccessfully: 'Mise à jour de la commande éffectuée avec succès !',
    created_at: 'Créee le',
    edit: 'Modifier la commande',
    AddCommande: 'Nouvelle commande',
    UserRequired: 'Vous devez sélectionner un utilisateur',
    selectCommandeTitle: 'Sélectionnez unde commande',
    noCommande: 'Non souscrit',
  },
  report: {
    reported: 'Reported',
    report: 'Report',
    reports: 'Les reports',
    subject: 'Le sujet',
    reasons: 'Description',
    SubjectRequired: 'Veuillez saisir le subject de la report',
    BodyRequired: 'Dites-nous en plus !',
    deleteWarn: 'Ceci supprimera la report',
    suppressionEffectue: 'Report supprimé avec succès',
    NewReport: 'Nouvelle Report',
    iscreatedSuccessfully: 'crée avec succès',
    isupdatedSuccessfully: "Mise à jour de l'report éffectuée avec succès !",
    created_at: 'Crée le',
    edit: "Modifier l'report",
    AddReport: 'Nouvelle report',
    UserRequired: 'Vous devez sélectionner un utilisateur',
    selectReportTitle: "Sélectionnez l'report",
  },
  discussion: {
    discussion: 'Discussion',
    discussions: 'Les discussions',
    sender: 'Initiateur',
    with: 'Avec',
  },
  produit: {
    name: 'Nom du produit',
    reported: 'Reported',
    report: 'Report',
    produits: 'Les produits',
    title: 'Nom',
    content: 'Description',
    TitleRequired: 'Veuillez saisir le nom du produit',
    contentRequired: 'Ajoutez un détails sur le produit !',
    deleteWarn: 'Ceci supprimera le produit',
    suppressionEffectue: 'Produit supprimé avec succès',
    NewReport: 'Nouvel Produit',
    iscreatedSuccessfully: 'crée avec succès',
    isupdatedSuccessfully: 'Mise à jour du produit éffectuée avec succès !',
    created_at: 'Crée le',
    edit: 'Modifier le produit',
    addTitle: 'Saisissez le nom du produit',
    CategorieRequired: 'Vous devez sélectionner une catégorie',
    selectCategorieTitle: 'Sélectionnez la catégorie',
    addTitleandImageMessage: "Veuillez s'il vous plaît saisir le nom du produit et ajouter l'image par défaut",
    addSummary: 'Ajouter une petite description (SEO)',
    addSummaryMessage: "Ajouter une petite description pouvant servir de sommaire pour le produit. Précisez l'importance qui servira à classer les produits dans la catégorie puis sélectionnez les allergènes disponibles",
    addCategory: 'Sélectionnez une catégorie',
    addCategoryMessage: 'Veillez sélectionner une catégorie pour cet produit',
    addTag: 'Ajoutez des tags (Emballage, Certifications) ! Quand voulez-vous publier ce produit?',
    addTagMessage: "Ici vous pouvez préciser l'heure de la publication, vous pouvez égalment ajouter des tags. sélectionnez les tags à ajouter ou créez-en !",
    SummaryRequired: 'Vous devez saisir un sommaire',
    CategoryRequired: 'Vous devez sélectionner une catégorie',
    Addproduit: 'Nouvel produit',
  },
  localite: {
    localites: 'Les localités',
    AddLocalite: 'Nouvelle localité',
    nolocalite: 'Pour commencer, cliquez sur le bouton (+) et ajoutez une localité ',
  },
  categorie: {
    categorie: 'Categorie',
    categories: 'Les categories',
    label: 'Label',
    description: 'Description',
    labelRequired: 'Veuillez saisir le label de la catégorie',
    descriptionRequired: "Un petit détails s'il vous plaît !",
    deleteWarn: 'Ceci supprimera la catégorie',
    suppressionEffectue: 'catégorie supprimée avec succès',
    NewCategorie: 'Nouvelle Catégorie',
    iscreatedSuccessfully: 'créee avec succès',
    isupdatedSuccessfully: 'Mise à jour de la catégorie éffectuée avec succès !',
    created_at: 'Créee le',
    edit: 'Modifier la catégorie',
    AddCategorie: 'Nouvelle catégorie',
    CategorieRequired: 'Vous devez sélectionner une catégorie',
    selectCategorieTitle: 'Sélectionnez une catégorie',
    produitCount: 'Produits',
  },
  tag: {
    tag: 'Tag',
    tags: 'Les tags',
    type: 'Type',
    label: 'Label',
    description: 'Description',
    labelRequired: 'Veuillez saisir le tag',
    deleteWarn: 'Ceci supprimera le tag',
    suppressionEffectue: 'Tag supprimé avec succès',
    NewTag: 'Nouvel Tag',
    iscreatedSuccessfully: 'crée avec succès',
    isupdatedSuccessfully: 'Mise à jour du tag éffectuée avec succès !',
    created_at: 'Crée le',
    edit: 'Modifier le tag',
    AddTag: 'Nouvel tag',
    selectTagTitle: 'Ajoutez des tags',
    typeRequired: 'Veillez sélectionner le type',
  },
};

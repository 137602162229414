import Vue from 'vue';
import Router from 'vue-router';

/**
 * Layzloading will create many files and slow on compiling, so best not to use lazyloading on devlopment.
 * The syntax is lazyloading, but we convert to proper require() with babel-plugin-syntax-dynamic-import
 * @see https://doc.soycain.dev/guide/advanced/lazy-loading.html
 */

Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router for modules */
import adminRoutes from './modules/admin';
import ArticleRoutes from './modules/articles';
import errorRoutes from './modules/error';

/**
 * Sub-menu only appear when children.length>=1
 * @see https://soycain.dev
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin', 'editor']   Visible for these roles only
    permissions: ['voir menu zip', 'manage user'] Visible for these permissions only
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb (default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
**/

export const asyncRoutes = [
  adminRoutes,
  ArticleRoutes,
  errorRoutes,
  { path: '*', redirect: '/404', hidden: true },
];

export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/index'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index'),
    hidden: true,
  },
  {
    path: '/auth-redirect',
    component: () => import(/* webpackChunkName: "auth-redirect" */ '@/views/login/AuthRedirect'),
    hidden: true,
  },
  {
    path: '/404',
    redirect: { name: 'Page404' },
    component: () => import(/* webpackChunkName: "404" */ '@/views/error-page/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import(/* webpackChunkName: "401" */ '@/views/error-page/401'),
    hidden: true,
  },
  {
    path: '/',
    component: Layout,
    redirect: { name: 'Accueil' },
    name: 'Dashboard',
    meta: { title: 'dashboard', icon: 'el-icon-s-home', noCache: false },

    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index'),
        name: 'Accueil',
        meta: { title: 'dashboard' },
      },
      {
        path: '/profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/users/UserProfile'),
        hidden: true,
        name: 'UserProfile',
        meta: { title: 'UserProfile' },
      },
    ],
  },
  {
    path: '/alerts',
    component: Layout,
    children: [{
      path: '',
      component: () => import(/* webpackChunkName: "alerts" */ '@/views/alerts/index'),
      name: 'alerts',
      meta: { title: 'alerts', icon: 'el-icon-bell' },
    }],
  },
  {
    path: '/commandes',
    component: Layout,
    children: [{
      path: '',
      component: () => import(/* webpackChunkName: "commandes" */ '@/views/commandes/index'),
      name: 'commandes',
      meta: { title: 'commandes', icon: 'el-icon-edit-outline' },
    }],
  },
];

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  base: '/',
  routes: constantRoutes,
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
